
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        













































.gift-popup,
[class*='gift-popup--'] {
  max-height: 80vh;
  padding: $spacing * 3 $spacing * 2;

  &.generic-popup {
    display: block;
    overflow: auto;
    max-width: 85rem;
    background: $white;
    border-radius: $border-radius;
  }

  @include mq(m) {
    padding: $spacing * 4.5 $spacing * 3.5;
  }
}

.gift-popup__wrapper {
  width: 13rem * 2;
  margin: 0 auto;

  @include mq(s) {
    width: 13rem * 3;
  }
  @include mq(m) {
    width: 13rem * 4;
  }
  @include mq(l) {
    width: 13rem * 5;
  }
}

.gift-popup__title {
  @extend %fw-medium;
  display: block;
  margin-bottom: $spacing * 1.5;
  text-align: center;

  @include mq($until: s) {
    br {
      display: none;
    }
  }

  @include mq(m) {
    margin-bottom: $spacing * 2.5;
    font-size: 3.2rem;
  }
}

.gift-popup__table {
  display: block;
  overflow: hidden;
  border: 1px solid $c-gray-lightest;
  border-radius: 5px;
}

.gift-popup__logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -2px -2px;
  padding: 0;
}

.gift-popup__logos__item {
  position: relative;
  width: 13rem;
  border: solid $c-gray-lightest;
  border-width: 0 1px 1px 0;
  list-style-type: none;

  @include mq(s, m) {
    &:nth-last-child(-n + 2) {
      // width: auto;
      border-bottom: 0;
    }

    &:nth-last-child(-n + 1) {
      border: 0;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }

  img {
    @include image-fit(contain);
  }
}

.gift-popup__link {
  display: table;
  margin: 0 auto;
  margin-top: $spacing * 1.5;

  @include mq(m) {
    margin-top: $spacing * 2;
  }
}
